import { ApiResponseEntity, PaginationEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import {
  InternalUserForgetPasswordEntity,
  InternalUserResetPasswordEntity,
  InternalUserSearchEntity,
  InternalUserCreateEntity,
  InternalUserUpdateEntity,
  InternalUserResponseEntity,
  InternalUserListResponseEntity
} from "../../models/backstages/InternalUserModels"
import { DropdownEntity, ExcelExportRequestEntity, ExcelImportResponseEntity, LoginEntity, LoginResponseEntity } from "@/models/commons/CommonModels";
import { Post } from "../commons/CommonService"
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";

/** 
 * 內部使用者登入
 * @param params InternalLoginEntity
 * @returns InternalUserEntity
 */
export async function InternalUserLogin(params: LoginEntity): Promise<AxiosResponse<ApiResponseEntity<LoginResponseEntity>, any>> {

  const url = "/api/backstage/InternalUser/Login";

  const result = await Post<LoginEntity, LoginResponseEntity>(url, params);

  return result;
}

/**
 * 忘記密碼
 * @param params InternalUserForgetPasswordEntity
 * @returns 
 */
export async function InternalUserForgetPassword(params: InternalUserForgetPasswordEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = "/api/backstage/InternalUser/ForgetPassword";

  const result = await Post<InternalUserForgetPasswordEntity, object>(url, params);

  return result;
}

/**
 * 重設密碼
 * @param params InternalUserResetPasswordEntity
 * @returns 
 */
export async function InternalUserResetPassword(params: InternalUserResetPasswordEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = "/api/backstage/InternalUser/ResetPassword";

  const result = await Post<InternalUserResetPasswordEntity, object>(url, params);

  return result;
}

/**
 * 內部使用者登出
 * @returns 
 */
export async function InternalUserLogout(): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = "/api/backstage/InternalUser/Logout";

  const result = await Post<object, object>(url, {});

  return result;
}

/** 
 * 根據 內部使用者 id 取得 內部使用者 資訊
 * */
export async function GetInternalUserById(id: number): Promise<AxiosResponse<ApiResponseEntity<InternalUserResponseEntity>, any>> {
  const url = "/api/backstage/InternalUser/GetById/" + id;
  const result = Post<object, InternalUserResponseEntity>(url, null!);

  return result;
}


/** 
 * 建立內部使用者
 * @returns Id
 * */
export async function CreateInternalUser(entity: InternalUserCreateEntity): Promise<AxiosResponse<ApiResponseEntity<number>, any>> {

  const url = "/api/backstage/InternalUser/Create";
  const result = Post<object, number>(url, entity);

  return result;
}

/** 
 * 更新內部使用者
 * @returns Id
 * */
export async function UpdateInternalUser(entity: InternalUserUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<any>>> {

  const url = "/api/backstage/InternalUser/Update";
  const result = Post<object, any>(url, entity);

  return result;
}

/** 
 * 條件搜尋內部使用者清單
 * */
export async function SearchInternalUser(request: InternalUserSearchEntity): Promise<AxiosResponse<ApiResponseEntity<PaginationEntity<InternalUserListResponseEntity>>, any>> {

  const url = "/api/backstage/InternalUser/Search";
  const result = await Post<object, PaginationEntity<InternalUserListResponseEntity>>(url, request);

  return result;
}

/** 
 * 移除內部使用者
 * */
export async function DeleteInternalUser(id: number): Promise<AxiosResponse<ApiResponseEntity<any>>> {

  const url = "/api/backstage/InternalUser/Delete/" + id;
  const result = await Post<object, any>(url, null!);

  return result;
}

/** 
 * 取得 內部用戶 下拉清單
 * */
export async function GetInternalUserDropdownList(): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {
  const url = "/api/backstage/InternalUser/GetInternalUserDropdownList";
  const result = await Post<object, Array<DropdownEntity>>(url, null!);

  return result;
}

/**
 * 根據查詢條件，輸出資料為 Excel Stream
 * @param entity ExcelExportRequestEntity<InternalUserSearchEntity>
 * @returns Stream
 */
export async function ExportInternalUserAsExcel(entity: ExcelExportRequestEntity<InternalUserSearchEntity>): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;
  const config: any = { withCredential: true, responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` } };
  const url = "/api/backstage/InternalUser/ExportInternalUserAsExcel";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

/**
 * 匯入 內部用戶 資料
 * @param entity any
 * @returns ExcelImportResponseEntity
 */
export async function ImportInternalUser(entity: any): Promise<AxiosResponse<ApiResponseEntity<ExcelImportResponseEntity>>> {
  const url = "/api/backstage/InternalUser/Import";
  const result = await Post<object, any>(url, entity);

  return result;
}