import { createRouter, createWebHistory } from "vue-router";

/**
 * 前台相關 View
 */
import StoreLayout from "../views/stores/layouts/StoreLayout.vue";
import StoreUserRegisterView from "../views/stores/StoreUserRegisterView.vue";
import StoreLoginView from "../views/stores/StoreLoginView.vue";
import ProductSearchView from "../views/stores/ProductSearchView.vue";
import OrderSearchView from "../views/stores/OrderSearchView.vue";
import UserInfoView from "../views/stores/UserInfoView.vue";
import PointHistoryView from "../views/stores/PointHistoryView.vue";
import LineAuthCallbackView from "../views/stores/LineAuthCallbackView.vue"
import LineRegisterView from "../views/stores/LineRegisterView.vue"
import AccountManagementView from "../views/stores/AccountManagementView.vue"
import ProductDetailView from "../views/stores/ProductDetailView.vue"

/**
 * 後台相關 View
 */
import BackStageLayout from '../views/backstages/layouts/BackStageLayout.vue'
import BackStageLoginView from '../views/backstages/BackStageLoginView.vue'
import SPUListView from '../views/backstages/SPUListView.vue'
import SPUInfoView from '../views/backstages/SPUInfoView.vue'
import OrderListView from '../views/backstages/OrderListView.vue'
import OrderInfoView from '../views/backstages/OrderInfoView.vue'
import ExternalBusinessListView from '../views/backstages/ExternalBusinessListView.vue'
import ExternalBusinessInfoView from '../views/backstages/ExternalBusinessInfoView.vue'
import InternalUserListView from '../views/backstages/InternalUserListView.vue'
import InternalUserInfoView from '../views/backstages/InternalUserInfoView.vue'
import VariableSettingView from '../views/backstages/VariableSettingView.vue'
import ExternalBizSkuSettingListView from '../views/backstages/ExternalBizSkuSettingListView.vue'
import ExternalBizSkuSettingInfoView from '../views/backstages/ExternalBizSkuSettingInfoView.vue'
import DiscountActivityListView from '../views/backstages/DiscountActivityListView.vue'
import DiscountActivityInfoView from '../views/backstages/DiscountActivityInfoView.vue'
import PointHistoryListView from '../views/backstages/PointHistoryListView.vue'
import PointHistoryInfoView from '../views/backstages/PointHistoryInfoView.vue'
import PointSettingView from '../views/backstages/PointSettingView.vue'
import PointToolView from '../views/backstages/PointToolView.vue'

/**
 * Admin
 */
import LogSearchView from "../views/admins/LogSearchView.vue";
import LoginView from "../views/admins/LoginView.vue";
import AdminLayout from "../views/admins/layouts/AdminLayout.vue";

/**
 * Common
 */
import { useAdminPersistStore } from "../store/AdminPersistStore";
import { useStorePersistStore } from "@/store/StorePersistStore";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";

const routes = [
  {
    path: "/Admin",
    name: "Admin",
    meta: { type: "Admin" },
    component: AdminLayout,
    children: [
      {
        path: "Login",
        name: "AdminLogin",
        meta: { title: "Admin登入" },
        component: LoginView,
      },
      {
        path: "LogSearch",
        name: "LogSearch",
        meta: { title: "Log查詢" },
        component: LogSearchView,
      },
    ],
  },
  {
    path: "/Store",
    name: "Store",
    meta: { type: "Store" },
    component: StoreLayout,
    children: [
      {
        path: "Login",
        name: "StoreLogin",
        meta: { title: "平台登入" },
        component: StoreLoginView,
      },
      {
        path: "UserRegister",
        name: "StoreUserRegister",
        meta: { title: "會員註冊" },
        component: StoreUserRegisterView,
      },
      {
        path: "ProductSearch/:id?",
        name: "ProductSearch",
        meta: { title: "商品查詢" },
        component: ProductSearchView,
      },
      {
        path: "OrderSearch",
        name: "OrderSearch",
        meta: { title: "訂單查詢" },
        component: OrderSearchView,
      },
      {
        path: "UserInfo",
        name: "UserInfo",
        meta: { title: "使用者設定" },
        component: UserInfoView,
      },
      {
        path: "PointHistory",
        name: "PointHistory",
        meta: { title: "點數歷程" },
        component: PointHistoryView,
      },
      {
        path: "LineAuthCallback",
        name: "LineAuthCallback",
        meta: { title: "LINE 登入" },
        component: LineAuthCallbackView
      },
      {
        path: "LineRegister",
        name: "LineRegister",
        meta: { title: "LINE 註冊 / 綁定" },
        component: LineRegisterView
      },
      {
        path: "AccountManagement",
        name: "AccountManagement",
        meta: { title: "商店帳號管理" },
        component: AccountManagementView
      },
      {
        path: "ProductDetail/:id?",
        name: "ProductDetail",
        meta: { title: "商品明細" },
        component: ProductDetailView
      }
    ],
  },
  {
    path: "/BackStage",
    name: "BackStage",
    meta: { type: "BackStage" },
    component: BackStageLayout,
    children: [
      {
        path: "Login",
        name: "BackStageLogin",
        meta: { title: "後台管理登入" },
        component: BackStageLoginView,
      },
      {
        path: "Spu/List",
        name: "SPUList",
        meta: { title: "商品列表", requiresAuth: true },
        component: SPUListView,
      },
      {
        path: "Spu/Create",
        name: "SPUCreate",
        meta: { title: "新增商品", requiresAuth: true },
        props: { mode: "Create" },
        component: SPUInfoView,
      },
      {
        path: "Spu/Edit/:id",
        name: "SPUEdit",
        meta: { title: "編輯商品", requiresAuth: true },
        props: { default: true, mode: "Edit" },
        component: SPUInfoView,
      },
      {
        path: "Order/List",
        name: "OrderList",
        meta: { title: "訂單列表", requiresAuth: true },
        component: OrderListView,
      },
      {
        path: "Order/Create",
        name: "OrderCreate",
        meta: { title: "新增訂單", requiresAuth: true },
        props: { mode: "Create" },
        component: OrderInfoView,
      },
      {
        path: "Order/Edit/:id",
        name: "OrderEdit",
        meta: { title: "編輯訂單", requiresAuth: true },
        props: { default: true, mode: "Edit" },
        component: OrderInfoView,
      },
      {
        path: "ExternalBusiness/List",
        name: "ExternalBusinessList",
        meta: { title: "客戶管理", requiresAuth: true },
        component: ExternalBusinessListView,
      },
      {
        path: "ExternalBusiness/Create",
        name: "ExternalBusinessCreate",
        meta: { title: "新增客戶", requiresAuth: true },
        props: { mode: "Create" },
        component: ExternalBusinessInfoView,
      },
      {
        path: "ExternalBusiness/Edit/:id",
        name: "ExternalBusinessEdit",
        meta: { title: "編輯客戶", requiresAuth: true },
        props: { default: true, mode: "Edit" },
        component: ExternalBusinessInfoView,
      },
      {
        path: "InternalUser/List",
        name: "InternalUserList",
        meta: { title: "使用者管理", requiresAuth: true },
        component: InternalUserListView,
      },
      {
        path: "InternalUser/Create",
        name: "InternalUserCreate",
        meta: { title: "新增使用者", requiresAuth: true },
        props: { mode: "Create" },
        component: InternalUserInfoView,
      },
      {
        path: "InternalUser/Edit/:id",
        name: "InternalUserEdit",
        meta: { title: "編輯使用者", requiresAuth: true },
        props: { default: true, mode: "Edit" },
        component: InternalUserInfoView,
      },
      {
        path: "VariableSetting",
        name: "VariableSetting",
        meta: { title: "商品分類設定", requiresAuth: true },
        component: VariableSettingView,
      },
      {
        path: "ExternalBizSkuSetting/List/:id?",
        name: "ExternalBizSkuSettingList",
        meta: { title: "客訂設定", requiresAuth: true },
        component: ExternalBizSkuSettingListView,
      },
      {
        path: "ExternalBizSkuSetting/Edit/:id/:externalBizId",
        name: "ExternalBizSkuSettingEdit",
        meta: { title: "編輯客訂設定", requiresAuth: true },
        component: ExternalBizSkuSettingInfoView,
      },
      {
        path: "DiscountActivity/List",
        name: "DiscountActivityList",
        meta: { title: "折扣活動列表", requiresAuth: true },
        component: DiscountActivityListView,
      },
      {
        path: "DiscountActivity/Edit/:id",
        name: "DiscountActivityEdit",
        meta: { title: "編輯折扣", requiresAuth: true },
        props: { mode: "Edit" },
        component: DiscountActivityInfoView,
      },
      {
        path: "DiscountActivity/Create/:id?",
        name: "DiscountActivityCreate",
        meta: { title: "新增折扣", requiresAuth: true },
        props: { mode: "Create" },
        component: DiscountActivityInfoView,
      },
      {
        path: "PointHistory/List",
        name: "PointHistoryList",
        meta: { title: "點數管理", requiresAuth: true },
        component: PointHistoryListView,
      },
      {
        path: "PointHistory/Edit/:id",
        name: "PointHistoryEdit",
        meta: { title: "編輯點數", requiresAuth: true },
        props: { mode: "Edit" },
        component: PointHistoryInfoView,
      },
      {
        path: "PointHistory/Create",
        name: "PointHistoryCreate",
        meta: { title: "新增點數", requiresAuth: true },
        props: { mode: "Create" },
        component: PointHistoryInfoView,
      },
      {
        path: 'PointHistory/PointSetting',
        name: 'PointSetting',
        meta: { title: '點數設定', requiresAuth: true },
        component: PointSettingView
      },
      {
        path: 'PointHistory/PointTool',
        name: 'PointTool',
        meta: { title: '點數工具', requiresAuth: true },
        component: PointToolView
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // 設定 browser tab title
  document.title = (to.meta.title as string) ?? "";

  const adminStore = useAdminPersistStore();
  const storeStore = useStorePersistStore();
  const backStageStore = useBackStagePersistStore();

  // 以 meta 切分前/後台登入機制
  switch (to.meta.type) {
    case "BackStage":
      // 後台
      if (backStageStore.isLogin() && backStageStore.isExpired() == false) {
        // 有登入，登入頁轉向後台 landing page
        if (to.name == "BackStage" || to.name == "BackStageLogin") {
          next({ name: "OrderList" });
        }
        // 有登入，頁面需驗證但無權限，轉向 landing page
        else if (
          to.matched.some((record) => record.meta.requiresAuth) &&
          backStageStore.isAllowed(to.name as string) == false
        ) {
          next({ name: "OrderList" });
        } else {
          next();
        }
      } else {
        if (backStageStore.isLogin()) {
          backStageStore.resetPermission();
        }

        if (to.name != "BackStageLogin") {
          next({ name: "BackStageLogin" });
        } else {
          next();
        }
      }
      break;

    case "Store":
      if (to.fullPath == "/Store" || to.fullPath == "/store") {
        next({ name: "ProductSearch" });
      }

      // 前台
      if (storeStore.isLogin() && storeStore.isExpired() == false) {
        //有登入，登入/註冊頁轉向 LandingPage
        if (to.name == "StoreLogin" || to.name == "StoreUserRegister") {
          next({ name: "ProductSearch" });
        } else {
          next();
        }
      } else {
        // 有登入，但登入期限過了，則前端清除 jwt token
        if (storeStore.isLogin()) {
          storeStore.resetPermission();
        }

        if (
          // 註冊 / 登入 / 產品搜尋頁，允許未登入後登入失效情境
          to.name == "StoreUserRegister" ||
          to.name == "StoreLogin" ||
          to.name == "ProductSearch" ||
          to.name == "LineAuthCallback" ||
          to.name == 'LineRegister' ||
          to.name == 'ProductDetail'
        ) {
          next();
        } else {
          next({ name: "ProductSearch" });
        }
      }

      break;

    case "Admin":
      if (adminStore.isLogin() == true) {
        if (to.name == "AdminLogin") {
          next({ name: "LogSearch" });
        }
        next();
      }

      if (to.name != "AdminLogin") {
        next({ name: "AdminLogin" });
      }

      next();
      break;

    default:
      // 非前後台，一率導向前台搜尋頁
      next({ name: "ProductSearch" });
      break;
  }
});

export default router;
