
import { defineComponent } from "vue";
import { ElNotification } from "element-plus";
import { ExternalBizSkuSettingInfoEntity } from "../../models/backstages/ExternalBizSkuSettingModel";
import {
  Update,
  GetById,
} from "../../services/backstages/ExternalBizSkuSettingService";
import ElCurrencyInput from "../../components/commons/ElCurrencyInput.vue";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import {
  ExternalBusinessSettingEntity,
  ExternalBusinessSettingSearchEntity,
} from "../../models/backstages/ExternalBusinessSettingModel";
import { GetByCode } from "../../services/backstages/ExternalBusinessSettingService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";

export default defineComponent({
  name: "ExternalBizSkuSettingInfoView",
  components: {
    ElCurrencyInput,
  },
  props: ["mode"],
  data() {
    return {
      title: "編輯客訂設定",
      loading: false,
      labelPosition: "right",
      id: +this.$route.params.id,
      extBizId: this.$route.params.externalBizId as string,

      extBizSkuInfoEntity: {} as ExternalBizSkuSettingInfoEntity,
      externalBusinessStatusDropdownList: [] as any,
      extBizSkuCalcTypeDropdownList: [] as any,
      defaultPriceSetting: {} as ExternalBusinessSettingEntity,

      /**
       * Validation rules
       */
      extBizSkuInfoEntityRules: {
        discount: [
          {
            validator: this.DiscountValidator,
            trigger: "blur",
          },
        ],
        price: [
          {
            validator: this.PriceValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },

  async created() {
    this.LoadData();
    await this.LoadDropdownList();
  },

  computed: {},
  methods: {
    async LoadData() {
      this.loading = true;
      const res = await GetById({ id: this.id });
      this.loading = false;
      this.extBizSkuInfoEntity = res.data.data;
    },

    async FormValidation(refs: any) {
      return refs.validate((valid: any) => {
        if (valid == false) {
          return valid;
        }
      });
    },

    LoadDropdownList() {
      Promise.all([
        this.GetBusinessStatusDef(),
        this.GetCalcTypeDef(),
        this.GetDefaultPriceSetting(),
      ]);
    },

    async GetDefaultPriceSetting() {
      const req: ExternalBusinessSettingSearchEntity = {
        externalBusinessId: parseInt(this.extBizId),
        groupCode: "CustomSkuSetting",
        code: "DefaultDiscount",
      };

      const res = await GetByCode(req);

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "設定檔載入失敗(預設折數)",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.defaultPriceSetting = res.data.data;
      }
    },

    async GetBusinessStatusDef() {
      // externalBusinessStatusDropdownList
      const req: DefinitionSearchEntity = {
        tableName: "external_business",
        columnName: "status",
      };

      const res = await GetDefinitionDropdownList(req);

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "設定檔載入失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.externalBusinessStatusDropdownList = res.data.data;
      }
    },

    async GetCalcTypeDef() {
      // externalBusinessStatusDropdownList
      const req: DefinitionSearchEntity = {
        tableName: "external_biz_sku_settings",
        columnName: "calc_type",
      };

      const res = await GetDefinitionDropdownList(req);

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "設定檔載入失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.extBizSkuCalcTypeDropdownList = res.data.data;
      }
    },

    /**
     * 更新
     */
    async DoUpdate() {
      let isValid = false;
      await this.FormValidation(this.$refs.extBizSkuInfoEntityRef).then(
        (result) => {
          isValid = result;
        }
      );

      if (!isValid) {
        ElNotification({
          title: "編輯客訂設定",
          message: "欄位未填寫或資訊有誤，請確認!",
          duration: 3000,
          type: "error",
        });
        return;
      }

      await Update(this.extBizSkuInfoEntity)
        .then((result: any) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "更新失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "更新設定",
              message: "更新設定成功",
              duration: 3000,
              type: "success",
            });

            this.LoadData();
          }
        })
        .catch((err) => {
          ElNotification({
            title: "更新失敗",
            message: "更新失敗!",
            duration: 3000,
            type: "error",
          });

          throw new Error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    HandleCalcTypeChangeEvent(val: string) {
      if (val == "Discount") {
        this.extBizSkuInfoEntity.price = undefined;
        this.extBizSkuInfoEntity.discount = parseFloat(
          this.defaultPriceSetting.value
        );
      } else if (val == "FixPrice") {
        this.extBizSkuInfoEntity.discount = undefined;
        this.extBizSkuInfoEntity.price = undefined;
      }
    },

    /*
    custom validator
    */
    DiscountValidator(rule: any, value: number, callback: any) {
      if (
        this.extBizSkuInfoEntity.calcType == "Discount" &&
        value == undefined
      ) {
        callback(new Error("請填寫指定折數"));
      }

      callback();
    },

    PriceValidator(rule: any, value: number, callback: any) {
      if (
        this.extBizSkuInfoEntity.calcType == "FixPrice" &&
        value == undefined
      ) {
        callback(new Error("請填寫指定金額"));
      }

      if (this.extBizSkuInfoEntity.calcType == "FixPrice" &&
        value < 0) {
        callback(new Error(("金額不可為負值")));
      }

      callback();
    },
  },
});
