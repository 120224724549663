import {
  SearchCriteriaEntity,
  ApiResponseEntity,
  PaginationEntity,
} from "@/models/ApiModels";
import {
  ExternalBizSkuSettingInfoEntity,
  ExternalBizSkuSettingListEntity,
  ExternalBizSkuSettingSearchEntity,
} from "@/models/backstages/ExternalBizSkuSettingModel";
import {
  ExcelImportResponseEntity,
  IdEntity,
} from "@/models/commons/CommonModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";

export async function Search(
  params: SearchCriteriaEntity<ExternalBizSkuSettingSearchEntity>
): Promise<
  AxiosResponse<
    ApiResponseEntity<PaginationEntity<ExternalBizSkuSettingListEntity>>,
    any
  >
> {
  const url = "/api/backstage/ExternalBizSkuSetting/Search";
  const result = await Post<
    SearchCriteriaEntity<ExternalBizSkuSettingSearchEntity>,
    PaginationEntity<ExternalBizSkuSettingListEntity>
  >(url, params);

  return result;
}

export async function GetById(
  params: IdEntity
): Promise<
  AxiosResponse<ApiResponseEntity<ExternalBizSkuSettingInfoEntity>, any>
> {
  const url = "/api/backstage/ExternalBizSkuSetting/GetById";
  const result = await Post<IdEntity, ExternalBizSkuSettingInfoEntity>(
    url,
    params
  );

  return result;
}

export async function Update(
  params: ExternalBizSkuSettingInfoEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = "/api/backstage/ExternalBizSkuSetting/Update";
  const result = await Post<ExternalBizSkuSettingInfoEntity, object>(
    url,
    params
  );

  return result;
}

export async function Export(
  entity: ExternalBizSkuSettingSearchEntity
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;
  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = "/api/backstage/ExternalBizSkuSetting/Export";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

export async function Import(
  entity: any
): Promise<AxiosResponse<ApiResponseEntity<ExcelImportResponseEntity>>> {
  const url = "/api/backstage/ExternalBizSkuSetting/Import";
  const result = await Post<object, any>(url, entity);

  return result;
}
